<template>
  <div class="wrapper">
    <div class="flex flex-col w-full">
      <div class="flex flex-col items-center justify-center">
        <div v-if="loaded" class="flex flex-col">
          <span>{{ token }}</span>
        </div>
        <div
          v-if="success"
          class="flex flex-col w-6/12 mobile:w-full mobile:px-2 mx-auto items-center my-auto"
        >
          <h2 class="text-green mb-8 mobile:text-center">
            Täname väga ostu eest!
          </h2>
          <span class="text-lg mb-8"
            >Kui oled Gewodo tööriistadega rahul, siis jaga infot ka oma
            tuttavatega. Nii jõuame oma lihtsa ja soodsa teenusega kõigi
            vajajateni ning saame jõudu uute tööriistade välja
            töötamiseks!</span
          >
          <span class="text-lg font-medium">Meeldivat koostööd soovides,</span>
          <span class="text-lg font-medium mb-8">Sinu Gewodo tiim</span>
          <img src="/logotext.png" class="mb-8 h-32 mobile:h-16" />
        </div>
        <div v-if="canceled" class="flex flex-col items-center">
          <h2 class="mb-8 mobile:text-center">Tellimine tühistatud</h2>
          <span class="mb-8 text-lg">
            {{ responseText }}
          </span>
        </div>
        <button
          @click="$router.push('/subscriptions')"
          class="new-button-green "
        >
          <span class="label">Tagasi ettevõtte lehele</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";

export default {
  name: "SubscriptionRedirect",
  mixins: [RequestHandler],
  data() {
    return {
      success: false,
      canceled: false,
      error: false
    };
  },
  methods: {
    sendPaymentToBackend() {
      this.apiRequest(
        "subscription/return/?payment_token=" + this.$route.query.payment_token,
        "post"
      ).then(res => {
        if (res.status === 200) {
          this.$store.dispatch("companyData/retrieveCompanyData");
          this.success = true;
        } else if (res.status === 202) {
          this.canceled = true;
        }
      });
    }
  },
  mounted() {
    this.sendPaymentToBackend();
  }
};
</script>
